/*
 * @Descripttion: 
 * @version: 
 * @Author: wzh
 * @Date: 2022-07-04 13:41:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-16 15:21:39
 */
import Vue from "vue";
const mapApi = Vue.prototype.mapApi;
import MapUntils from "../untils/mapUntils.js";
import baseMapSource from "../baseLayer.js";
import mapPopup_urban from "../component/mapPopup_urban.vue";
import mapPopup_warning from "../component/mapPopup_warning.vue";
import popupMove from "../untils/popupMove.js";
import WaringPointLayer from "../layers/WaringPointLayer.js";
import UrbanPointLayer from "../layers/UrbanPointLayer.js";
let mapPopupVm_urban = Vue.extend(mapPopup_urban);
let mapPopupVm_warning = Vue.extend(mapPopup_warning);
export default class BasicMap {
    constructor(parm) {
        this.initView = this.initView.bind(this);
        this.initLayers = this.initLayers.bind(this);
        this.initMap = this.initMap.bind(this);
        this.initEvents = this.initEvents.bind(this);

        this.target_ = parm.target;//地图所在dom id
        this.mapUntils_ = new MapUntils();//地图需要的工具类
        this.map_ = undefined;//地图对象
        this.view_ = undefined;//视图对象
        this.layerArray_ = undefined;//图层数组

        this.initView();
        this.initMap();//要在view_存在后
        this.initLayerArr();//要在map_存在后
        this.initLayers();//要在layerArray_存在后

        this.initMapPopup();//初始化地图气泡
        this.initEvents();//要在map_存在后 若有overlay要在overlay之后 #一般是放在initBusiness之前
        this.cilckWarningPoint();

        //所有的组件初始化完成后地图才能运行
    }

    //#region 地图初始化
    /**
     * 初始化视图
     */
    initView() {
        this.view_ = new mapApi.View({
            center: Vue.prototype.mapEnum.mapExtent.center,
            zoom: Vue.prototype.mapEnum.mapExtent.zoom,
            projection: "EPSG:4326",
        });
    }
    /**
     * 初始化地图
     */
    initMap() {
        let th = this;
        let _base = baseMapSource.newBaseMapLayer();
        this.baseMapSource_ = baseMapSource;
        baseMapSource.addSourceToMap(Vue.prototype.mapEnum.baseLayerType.TDTvector_blue);
        th.map_ = new mapApi.Map({
            controls: mapApi
                .controlDef({
                    attribution: false,
                    zoom: false,
                    rotate: false,
                })
                .extend([]),
            view: th.view_,
            target: th.target_,
            layers: _base,
        });
        // this.view_.fit(Vue.prototype.mapEnum.mapExtent.extent, { padding: Vue.prototype.mapEnum.mapExtent.padding })
    }
    /**
     * 初始化图层数组
     */
    initLayerArr() {
        this.layerArray_ = this.mapUntils_.createLayerArray(this.map_);
    }
    //初始化图层
    initLayers() {
        // let waringPointLayer = new WaringPointLayer();
        // let urbanPointLayer = new UrbanPointLayer();
        // this.layerArray_.add(urbanPointLayer, waringPointLayer);
    }
    initMapPopup() {

    }

    //初始化事件
    initEvents() {
        let th = this;
        // //初始化高亮 （带弹窗）
        // let options = {
        //     map: th.map_,
        //     layerArray: th.layerArray_,
        //     mapPopupVm: th.mapPopupVm_,
        //     overlayForm: th.overlayForm_
        // }
        // this.selectInteraction_ = this.mapUntils_.loadInteraction(options);
        th.map_.on('click', (e) => {
            let pixel = th.map_.getEventPixel(e.originalEvent);
            let coordinate = th.map_.getCoordinateFromPixel(pixel);
            let selected = [];
            th.map_.forEachFeatureAtPixel(pixel, (feature, layer) => {
                selected.push({ feature: feature, layer: layer });
            });

            for (let ftObj of selected) {
                let layerCom = ftObj.feature.get("layerCom");

                let popup;
                if (layerCom == this.layerArray_[1]) popup = new popupMove(th.map_, mapPopupVm_warning);
                else popup = new popupMove(th.map_, mapPopupVm_urban);

                layerCom.doIdentify({ selected: [ftObj.feature] }, popup.mapPopupVm, popup.overlayForm);
                popup.show(coordinate);
                break;
            }
        })
    }

    cilckWarningPoint() {
        let th = this;
        setTimeout(() => {
            let popup = new popupMove(th.map_, mapPopupVm_warning);
            let layerCom = th.layerArray_[1];
            let fts = layerCom.source.getFeatures();
            let ft = fts[0];
            popup.show(ft.getGeometry().getCoordinates());
            layerCom.doIdentify({ selected: [ft] }, popup.mapPopupVm, popup.overlayForm);
        }, 500);
    }

    //#endregion


}