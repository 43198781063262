/*
 * @Descripttion: 
 * @version: 
 * @Author: wzh
 * @Date: 2022-07-06 09:19:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-01 15:38:49
 */
import Vue from "vue";
import axios from 'axios';
const mapApi = Vue.prototype.mapApi;
export default class WaringPointLayer {
    constructor() {
        this.layer = null;
        this.source = null;
        this.layerName = "报警点图层";
        this.layerId = Vue.prototype.mapEnum.layerName.WaringPointLayer;
        this.features = [];
        this.clickCallBack = function () { };//business层声明
        this.audio = new Audio(require("/src/assets/alarm.mp3"));
        this.audio.loop = true;

        //绑定this
        this.styleFun = this.styleFun.bind(this);
        this.creatLayer = this.creatLayer.bind(this);
        this.initData = this.initData.bind(this);
        this.requestData = this.requestData.bind(this);
        this.creatLayer();
        this.initData({});
        this.playSound();
    }

    styleFun(feature, resolution) {
        //图标
        let src = require("/src/assets/map/warning.png");

        let label = new mapApi.Text({
            textAlign: 'center',     //对齐方式
            textBaseline: 'middle',    //文本基线
            text: "烟雾报警",    //文本内容
            font: 'normal 16px 微软雅黑',
            offsetY: 16,    // X轴偏置
            fill: new mapApi.Fill({        //填充样式
                color: '#d95151'
            }),
            padding: [2, 5, 2, 5],
        })

        return new mapApi.Style({
            image: new mapApi.Icon({
                src: src,
                anchor: [0.5, 1],
            }),
            text: label,
        })
    }

    styleFun_heighLight(feature, resolution) {
        let label = new mapApi.Text({
            textAlign: 'center',     //对齐方式
            textBaseline: 'middle',    //文本基线
            text: "烟雾报警",    //文本内容
            font: 'normal 16px 微软雅黑',
            offsetY: 16,    // X轴偏置
            fill: new mapApi.Fill({        //填充样式
                color: '#d95151'
            }),
            padding: [2, 5, 2, 5],
        })

        return new mapApi.Style({
            image: new mapApi.Icon({
                src: src,
                anchor: [0.5, 1],
            }),
            text: label,
        })
    }

    creatLayer() {
        let th = this;
        this.layer = new mapApi.VectorLayer({
            id: th.layerId,
            name: th.layerName,
            visible: true,
            style: th.styleFun,
        });
        this.source = new mapApi.VectorSource({
            projection: "EPSG:4326",
        });
        this.layer.setSource(this.source);

    }

    //layer中添加source
    async initData(fromData) {
        let th = this;
        let result = await this.requestData(fromData);
        result.forEach(e => {
            var feature = new mapApi.Feature({
                geometry: new mapApi.Point([parseFloat(e.lon), parseFloat(e.lat)])
            });
            feature.set("attr", e);
            feature.set("layerCom", th);//非常重要，很多事件都可能用到
            th.features.push(feature);
        })
        th.source.addFeatures(th.features);

    }

    async requestData(fromData) {
        //接口请求
        // let res = await store.dispatch('timeData/getVolunteerBoatTrack', fromData);
        // if (res.success) {
        //     return res.result;
        // } else {
        //     Vue.prototype.$message.error(res.message)
        //     return null;
        // }
        //静态返回
        return require("../data/warning.json")
        //静态数据模拟接口返回
        // let res = await axios.get('static/bdData.json', fromData);
        // return res.data;
    }

    //单击事件 无tags 单选
    doIdentify(parm, mapPopupVm, overlayForm) {
        let th = this;
        if (overlayForm && mapPopupVm && parm.selected[0]) {
            // mapPopupVm.show = true;
            overlayForm.setPosition(undefined)
            let ftAttr = parm.selected[0].get("attr");
            mapPopupVm.property.length = 0;

            for (var key in ftAttr) {
                if (key == "lat" || key == "lon") continue;
                mapPopupVm.property.push({ name: key + "：", value: ftAttr[key] ? ftAttr[key] : "" })
            }
            mapPopupVm.property.push({ name: "经纬度：", value: ftAttr["lon"] + "，" + ftAttr["lat"] })
            mapPopupVm.title = "报警信息"
            overlayForm.setPosition(parm.selected[0].getGeometry().getCoordinates())
            th.clickCallBack(ftAttr)
            th.audio.play();
            mapPopupVm.closeCallback_bus = () => { th.audio.pause(); };
        }
        else {
            th.clickCallBack(undefined)
        }
    }

    //单击事件 有tags 多选 窗口位置控制在mapuntils里
    doIdentify_multi(feature, mapPopupVm, overlayForm, map) {

    }


    //鼠标悬停
    hover(evt, ft, comArr) {

    }

    //鼠标悬停结束（移出）
    hoverout(evt, comArr) {

    }
    /**
     * 过滤显示要素 只能是同步，且要加loading蒙版
     * @param {*}  visible 是否显示
     * @param {*} filter ["字段名称"，"字段值"] *该属性不存在时，控制整个图层的显隐
     */
    featurefilter(visible, filter) {
        let th = this;

        if (!filter) {
            if (visible) th.source.addFeatures(th.features);
            else th.source.clear();
            return;
        }

        let fts = th.features.filter(e => { return e.get("attr")[filter[0]] == filter[1] })
        if (!visible) {
            fts.forEach(e => {
                th.source.removeFeature(e);
            })
        }
        else th.source.addFeatures(fts);
    }

    playSound() {
        let th = this;
        let interval = setInterval(() => {
            let promise = th.audio.play();
            if (promise !== undefined) {
                promise.then(res => {
                    clearInterval(interval);
                }).catch(error => {
                    console.info("先对页面进行操作");
                });
            }
        }, 1000);


    }
}