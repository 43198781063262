

import Vue from "vue";
const mapApi = Vue.prototype.mapApi;
export default class mapUntils {
  constructor() {
    this.singleClickFlag = Object.freeze({
      selectFeature: Symbol("selectFeature"),
      getCoords: Symbol("getCoords"),
    })
  };


  //#region 初始化相关
  /**
   * 添加add，remove方法创造数组，达到同时移除地图图层的目的
   * @param {*} map
   */
  createLayerArray(map) {
    let newPrototype = Object.create(Array.prototype);
    var add_f = function (...args) {
      let curArr = this;
      //添加地图图层
      args.forEach((e) => {
        if (e.layer && map) {
          map.addLayer(e.layer);
        }
      });
      return Array.prototype.push.call(curArr, ...args);
    };
    var remove_f = function (...args) {
      let curArr = this;
      args.forEach((e) => {
        //删除地图图层
        if (e.layer && map) {
          map.removeLayer(e.layer)
        }
        //删除数组元素
        let index = curArr.indexOf(e);
        if (index > -1) {
          curArr.splice(index, 1);
        }
      });
    };
    //add添加
    newPrototype.add = add_f;
    //添加remove方法
    newPrototype.remove = remove_f;
    //绑定新的原型
    let arr = new Array();
    arr.__proto__ = newPrototype;

    //监听数组 防止vue修改__proto__后移除 add 和remove方法
    var obj = new Proxy(arr, {
      get: function (target, key, receiver) {
        return target[key]
      },
      set: function (target, key, value, receiver) {
        if (key == "__proto__") {
          value.add = add_f;
          value.remove = remove_f;
        }
        target[key] = value;
        return true
      }
    })
    return obj;

  }
  //#endregion

  //#region 常用工具
  /**
   * 根据id获取图层
   * @param {*框架中的图层数组} layerArray
   * @param {*图层id} layerid
   */
  getLayerComById(layerArray, layerid) {
    for (let i = 0; i < layerArray.length; i++) {
      let layer = layerArray[i];
      if (layer.layerId == layerid) return layer;
    }
    return null;
  }
  //#endregion

  //#region 地图交互
  /**
   * 点击选择高亮工具（参数固定）只允许一个弹窗
   * @param {*options} layerArray
   {     
      map 必填 ol地图对象
      layerArray 必填 框架中的图层数组
      mapPopupVm 选填 若有弹框 弹框的vue页面组件
      overlayForm 选填 若有弹框 ol的overlay
    }
   */
  loadInteraction(options) {
    let layers = [];

    let map = options.map;
    let layerArray = options.layerArray;
    let mapPopupVm = options.mapPopupVm;//弹出窗vue组件
    let overlayForm = options.overlayForm;//弹出窗ol控件

    layerArray.forEach((e) => {
      layers.push(e.layer);
    });
    //新建交互控件
    let Interaction = new mapApi.InteractionSelect({
      style: function (ft, resolution, aa) {
        let style = {};
        if (ft.get("layerCom")) {
          style = ft.get("layerCom").styleFun_heighLight(ft, resolution);
          return style;
        }

      },
      layers: layers,
    });
    //交互控件挂载事件
    Interaction.on("select", (e) => { //disselect 也会触发
      let ft = null;
      if (Vue.prototype.mapGlobal.measureStatus) return;
      if (e.selected[0]) { //选中
        ft = e.selected[0];

        if (mapPopupVm && overlayForm)  //初始化位置
          overlayForm.setPosition(undefined)

        if (ft && ft.get("layerCom") && ft.get("layerCom").doIdentify)
          ft.get("layerCom").doIdentify(e, mapPopupVm, overlayForm, map);
      }
      else {//取消
        ft = e.deselected[0];

        if (mapPopupVm && overlayForm)
          overlayForm.setPosition(undefined)

        if (ft && ft.get("layerCom") && ft.get("layerCom").doIdentify)
          ft.get("layerCom").doIdentify(e, mapPopupVm, overlayForm, map);
      }
    });
    map.addInteraction(Interaction);
    // layerArray.forEach((e) => {
    //   e._selectInteraction = Interaction;
    // });
    return Interaction;
  }

  /**
   * 点击选择高亮工具 多个选择 单个高亮
   * 只能选中和第一个要素同图层的所有要素
   * 不通用 绑定 "../component/mapPopup_tabs.vue"
   * @param {*} map 
   * @param {*} layerArray 
   * @returns 
   */
  loadInteraction_multi(options) {
    let layers = [];

    let map = options.map;
    let layerArray = options.layerArray;
    let mapPopupVm = options.mapPopupVm;//弹出窗vue组件
    let overlayForm = options.overlayForm;//弹出窗ol控件

    layerArray.forEach((e) => {
      layers.push(e.layer);
    });
    let Interaction = new mapApi.InteractionSelect({
      style: function (ft, resolution) {
        let style = {};
        if (ft.get("layerCom")) style = ft.get("layerCom").styleFun_heighLight(ft, resolution);
        return style;
      },
      multi: true,
      layers: layers,
    });
    Interaction.on("select", (e) => {
      if (Vue.prototype.mapGlobal.measureStatus) return;
      if (e.selected[0] && e.selected.length > 1) {//只能选中和第一个要素同图层的所有要素
        let ft_1sCom = e.selected[0].get("layerCom");
        let my_fts = Interaction.getFeatures()
          .getArray()
          .filter((e) => {
            return ft_1sCom != e.get("layerCom");
          });
        my_fts.forEach((e) => {
          Interaction.getFeatures().remove(e);
        });
        e.selected = Interaction.getFeatures().getArray();
      }

      if (e.selected[0]) { //选中录入tags页
        if (mapPopupVm && overlayForm)  //初始化位置
        {
          overlayForm.setPosition(e.mapBrowserEvent.coordinate)
          mapPopupVm.property.length = 0;
        }

        e.selected.forEach(ft => {//触发每个图层的都identify
          if (ft && ft.get("layerCom") && ft.get("layerCom").doIdentify_multi) {
            ft.get("layerCom").doIdentify_multi(ft, mapPopupVm, overlayForm, map);
          }
          else overlayForm.setPosition(undefined)
        })

        mapPopupVm.tabChangeCallback = (ft) => { //tab 切换事件 切换高亮
          Interaction.getFeatures().clear();
          Interaction.getFeatures().push(ft);
          // th.clickCallBack(ft.get("attr")) //回调逻辑在bussiness 用于触发三维地图高亮
        }
      }
      else {//取消
        if (mapPopupVm && overlayForm) {
          mapPopupVm.property.length = 0;
          overlayForm.setPosition(undefined);
        }
      }
    });
    map.addInteraction(Interaction);
    return Interaction;
  }

  /**
   * 悬停选择工具，地图事件，无高亮（参数固定）
   * @param {*ol地图对象} map
   * @param {*图层需要操作的控件数组，比如overlay和tooltips} comArr
   */
  loadSelect_Hover(map, layerArray, comArr) {
    return map.on("pointermove", function (e) {
      if (layerArray) layerArray.forEach(lc => {
        if (lc.hoverout) lc.hoverout(e, comArr);
      })
      map.forEachFeatureAtPixel(e.pixel, (ft) => {
        if (Vue.prototype.mapGlobal.measureStatus) return true;
        if (ft && ft.get("layerCom") && ft.get("layerCom").hover)
          ft.get("layerCom").hover(e, ft, comArr);
        return true;
      });
    });
  }
  //#endregion


  //#region 暂时无用
}

