<!--
 * @Descripttion: 基础用法
 * @version: 
 * @Author: wzh
 * @Date: 2022-09-08 11:15:41
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-01 15:18:38
-->
<template>
  <div id="mapContent">
    <div id="OLmap_LargeScreenViewer" class="map">
    </div>
  </div>
</template>
<script>
// import mapSwitcherVue from "../component/BaseMapSwitcher.vue"; //左下角切换底图
import LargeScreenMap from "../maps/LargeScreenMap";
export default {
  name: "OLmap_LargeScreenViewer",
  components: {},
  props: [],
  data() {
    return {
      //自定义的控件
      OLMap: undefined,
      mapUntils: undefined,
      //api的控件
      ol_map: undefined,
      //工具条查询用到的图层
      toolbar_querylayers: [],
    };
  },
  created() { },
  mounted() {
    this.initOL();
  },
  updated() { },
  methods: {
    //#region 地图初始化
    /**
     * 初始化视图
     */
    initOL() {
      this.OLMap = new LargeScreenMap({
        target: "OLmap_LargeScreenViewer",
      });
      this.mapUntils = this.OLMap.mapUntils_;
      this.ol_map = this.OLMap.map_;
      this.toolbar_querylayers = this.OLMap.layerArray_.map((e) => {
        return e.layer;
      });
    },

    //#endregion
  },
};
</script>

<style>
#mapContent {
  position: relative;
  width: 100%;
  height: 100%;
}

#OLmap_LargeScreenViewer {
  height: 100%;
  width: 100%;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}
</style>
